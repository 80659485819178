import { connect } from 'react-redux';

import { getSortByQueryParam } from 'redux/modules/search/selectors';
import pushPathWithNewSortByParams from 'redux/modules/routing/actions/push-path-with-new-sortby-params';

import SortBy from './SortBy';

export default connect(
  state => ({
    currentValue: getSortByQueryParam(state),
    hasResults: state.searchAndBrowse.hasResults,
    isLoading: state.searchAndBrowse.loading,
  }),
  {
    push: pushPathWithNewSortByParams,
  },
)(SortBy);
