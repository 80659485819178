import { SORTBY } from 'redux/modules/search/actions/types';

import { getSearchQueryFromApiRequest } from 'utils/searchQuery';

export const sortBy =
  ({ order, stickyFilter }) =>
  (dispatch, getState) => {
    dispatch({
      type: SORTBY,
      order,
      stickyFilter,
    });

    return getSearchQueryFromApiRequest(getState().search.queryParams);
  };
